import React from 'react';
import { Box, cn } from '@superside/ui';
import { type SanityImage } from '@konsus/image';
import { PageSection, type VisualAssetProps } from '../../components';
import { SectionHeader, type SectionHeaderProps } from '../../components/SectionHeader';
import { SectionButton } from '../../components/SectionButton';
import { KeyFeaturesItem } from './KeyFeaturesItem';

export interface KeyFeaturesProps {
  header?: SectionHeaderProps;
  ctaText?: string;
  ctaUrl?: string;
  cols?: 3 | 4;
  items: {
    descriptionText: string;
    descriptionRichText: { content: Array<any> };
    image?: SanityImage;
    asset?: VisualAssetProps;
    title?: string;
    url?: string;
    _key: string;
  }[];
}

export const KeyFeatures: React.FunctionComponent<KeyFeaturesProps> = (props) => {
  const { header, ctaText, ctaUrl, items, cols = 4, ...rest } = props;

  return (
    <PageSection {...rest}>
      <Box
        className={cn(
          'flex flex-col items-start',
          '768:flex-row 768:justify-between 768:mb-[66px] 1440:mb-20 mb-11'
        )}
      >
        {header ? <SectionHeader {...header} flex={true} margin={{ bottom: '6' }} /> : null}

        {ctaText && ctaUrl ? (
          <Box className='768:pl-6'>
            <SectionButton primary href={ctaUrl} label={ctaText} />
          </Box>
        ) : null}
      </Box>

      {items ? (
        <div
          className={cn(
            '768:grid-cols-2 1024:gap-5 1440:gap-6 grid grid-cols-1 gap-4',
            cols === 3 && '1024:grid-cols-3',
            cols === 4 && '1024:grid-cols-4'
          )}
        >
          {items.map((item) => (
            <KeyFeaturesItem
              className='after:bg-light after:absolute after:left-0 after:bottom-0 after:h-[5px] after:w-full after:rounded-lg after:opacity-20 after:content-[""]'
              key={item._key}
              {...item}
            />
          ))}
        </div>
      ) : null}
    </PageSection>
  );
};

export default KeyFeatures;
